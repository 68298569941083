import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import USP from "../../components/usp"
import HeroSectionAlt from "../../components/heroSectionAlt"
import Testimonials from "../../components/testimonials"

function WoodlandsPlumber() {
  const data = useStaticQuery(graphql`
    query woodlandsImages {
      woodlandsPlumber: file(relativePath: { eq: "woodlands-plumber.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Woodlands Plumbing Company"
        description={`Our company has been providing plumbing services for the Woodlands community for over a decade. We offer all kinds of residential plumbing repairs. `}
      />
      <HeroSectionAlt
        h1={`Woodlands Plumbing`}
        h2={`Your Local Woodlands Master Plumber`}
        h3={`
        Our experienced team has been proudly serving the Woodlands area for nearly two decades. Started by Sid Bruton in 2006, Klein Plumbing Services offers superior plumbing, sewer, and water heater repair to the Woodlands community.
        `}
        p1={`The mission of our family-owned and operated company is simple: to provide fair, honest, & tailored plumbing solutions to all members of our North Houston family. We pride ourselves on our expertise and transparency with every client.`}
        heroImg={data.woodlandsPlumber.childImageSharp.fluid}
        heroImgAltTag={`Woodlands Plumbing Company`}
      />
      <section>
        <div className="pb-16 px-2 md:px-0">
          <h2 className="font-bold leading-8 text-2xl mb-4">
            About Klein Plumbing Services
          </h2>
          <div className="flex flex-col-reverse lg:flex-row">
            <div className="md:w-full lg:mr-8">
              <div className="md:flex">
                <p className="text-gray-600 leading-normal text-lg mb-4"></p>
              </div>
              <div className="">
                <p className="text-gray-600 text-lg mb-4">
                  After years of experience, we’re able to handle any imaginable
                  plumbing problem Woodlands residents might encounter; from
                  ruptured water lines to leaky faucets, we can provide
                  assistance to get your home functioning the way it should.
                </p>
                <p className="text-gray-600 text-lg mb-4">
                  We understand plumbing issues are the last thing you want to
                  deal with - we work to accommodate your schedule so you can
                  have your plumbing up and running again as soon as possible.
                  Our Woodlands team is licensed, insured, and are the right
                  plumbers for the job.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <USP />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <CTA
        title={`Get a Free Quote Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default WoodlandsPlumber
